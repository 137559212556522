<template>
  <div class="contactUs">
    <div class="banner"></div>
    <div class="contact">
      <div class="content">
        <div class="contactWay clearfix">
          <div
            class="title wow bounceInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            <span>联系方式</span>
            <div class="line"></div>
          </div>
          <div class="msgList">
            <ul
              class="wow bounceInLeft"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <li><span>联系电话：</span><span>020-00000000</span></li>
              <li>
                <span>手机号码：</span><span>13000000000;100000000000</span>
              </li>
              <li>
                <span>传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;真：</span
                ><span>020-000-000</span>
              </li>
              <li>
                <span>邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱:</span
                ><span>131564315454@qq.com</span>
              </li>
              <li><span>联系QQ:</span><span>258506508</span></li>
              <li>
                <span>联系地址:</span
                ><span>上海市青浦区达芙妮集团工厂821室</span>
              </li>
            </ul>
          </div>
          <div
            ref="myMap"
            id="map"
            class="wow bounceInRight"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          ></div>
        </div>
        <el-form
          ref="form"
          :model="form"
          label-width="80px"
          :inline="true"
          class="wow fadeIn"
          data-wow-duration="1s"
          data-wow-delay="0.5s"
        >
          <el-form-item label="姓名" :required="true">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="时间" :required="true">
            <el-date-picker
              v-model="form.time"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="手机" :required="true">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="常用邮箱" :required="true">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item label="留言" :required="true" class="textarea">
            <el-input
              v-model="form.message"
              type="textarea"
              :rows="5"
              show-word-limit
              maxlength="200"
              resize="none"
            ></el-input>
          </el-form-item>

          <el-button type="primary" style="margin-left: 80px" @click="onSubmit"
            >提交</el-button
          >
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  data() {
    return {
      form: {
        name: "",
        time: "",
        phone: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    createMap() {
      var map = new BMap.Map(this.$refs.myMap, {
        coordsType: 5, // coordsType指定输入输出的坐标类型，3为gcj02坐标，5为bd0ll坐标，默认为5。
        // 指定完成后API将以指定的坐标类型处理您传入的坐标
      }); // 创建地图实例
      var point = new BMap.Point(121.1821, 31.152872); // 创建点坐标
      map.centerAndZoom(point, 18);
      map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
      map.addControl(
        new BMap.NavigationControl({ anchor: BMAP_ANCHOR_TOP_RIGHT })
      );
    },
    onSubmit() {},
  },
  mounted() {
    this.createMap();
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
    });
    wow.init();
  },
};
</script>

<style lang="less" scoped>
.clearfix:after {
  content: ""; /*设置内容为空*/
  height: 0; /*高度为0*/
  line-height: 0; /*行高为0*/
  display: block; /*将文本转为块级元素*/
  visibility: hidden; /*将元素隐藏*/
  clear: both; /*清除浮动*/
}
.clearfix {
  zoom: 1; /*为了兼容IE*/
}
#map ::v-deep.BMap_cpyCtrl {
  display: none;
}
#map ::v-deep .anchorBL {
  display: none;
}
.contactUs {
  .banner {
    background: url("//20601220.s61i.faiusr.com/2/AD0IhLPpCRACGAAgoNuU7QUosv-fjwUwgA84rAI.jpg")
      no-repeat 50% 50%;
    height: 300px;
  }
  .contact {
    .content {
      width: 1200px;
      background: #f7f7f7;
      margin: 80px auto;
      padding: 105px 0 78px 110px;
      box-sizing: border-box;
      .contactWay {
        width: 1005px;
        height: 450px;
        border-bottom: 1px solid #ccc;
        .title {
          font-size: 30px;
          color: rgb(53, 53, 53);
          height: 71px;
          position: relative;
          .line {
            position: absolute;
            background: rgb(30, 80, 174);
            width: 50px;
            height: 2px;
            top: 50px;
            left: 0;
            display: block;
          }
        }
        .msgList {
          font-size: 16px;
          line-height: 46px;
          color: #666;
          width: 400px;
          float: left;
          ul {
            li {
              span {
                &:nth-of-type(1) {
                  width: 80px;
                  display: inline-block;
                }
              }
            }
          }
        }
        #map {
          float: left;
          //background: red;
          width: 500px;
          height: 300px;
          margin-left: 100px;
        }
      }
    }
  }
  .el-form {
    padding-top: 40px;
    .textarea {
      display: block;
      .el-textarea {
        width: 800px;
        font-size: 12px;
      }
    }
    .el-input {
      width: 350px;
    }
  }
}
</style>